import React, {Component} from 'react';
import logo from "../../../../assets/images/logo.png";
import nagad from "../../../../assets/images/Nagad-Logo.wine.png";
import bkash from "../../../../assets/images/bkash_logo.png";
import {useInternalLink} from "@/Library/Helper";

export default function ClientFooter ({company}) {
    return (
        <>
            <footer className="rts-footer site-footer-four">
                <div className="container d-block position-relative z-1">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 rts-footer__widget--column">
                            <div className="rts-footer__widget footer__widget w-230">
                                <div className="rts-header__logo mb-4">
                                    <a href="#" className="site-logo">
                                        <img className="logo-white" src={company?.logo?useInternalLink(company?.logo):logo} width={50} alt="Power IT BD"/>
                                        <span className="company-name">{company?.name?company?.name:"Power IT BD"}</span>
                                    </a>
                                </div>

                                <p className="brand-desc address">{company?.address||"address"}</p>
                                <div className="separator site-default-border"></div>

                                <div className="contact-method">
                                    <a href="tell:121"><span><i className="fa-regular fa-phone"></i></span>{company?.mobile||""}</a>
                                    <a href={`mailto:${company?.email||""}`}>
                                        <span>
                                        <i className="fa-light fa-envelope"></i>
                                    </span>{company?.email||""}</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-4 rts-footer__widget--column">
                            <div className="rts-footer__widget footer__widget extra-padding">
                                <h5 className="widget-title">Company</h5>
                                <div className="rts-footer__widget--menu ">
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Contact</a></li>
                                        <li><a href="#">Our Technology</a></li>
                                        <li><a href="#">Knowledgebase</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-4 rts-footer__widget--column">
                            <div className="rts-footer__widget footer__widget extra-padding">
                                <h5 className="widget-title">Feature</h5>
                                <div className="rts-footer__widget--menu ">
                                    <ul>
                                        <li><a href="#">FTP Server</a></li>
                                        <li><a href="#">Gameing Server</a></li>
                                        <li><a href="#">Data Centers</a></li>
                                        <li><a href="#">Support</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 rts-footer__widget--column no-margin">
                            <div className="rts-footer__widget footer__widget">
                                <h5 className="widget-title">Join Our Network</h5>
                                <p>We'll send you news and offers.</p>
                                <form action="#" className="newsletter">
                                    <input type="email" name="email" placeholder="Enter mail" required/>
                                    <span className="icon"><i className="fa-regular fa-envelope-open"></i></span>
                                    <button type="submit"><i className="fa-regular fa-arrow-right"></i></button>
                                </form>
                                <div className="social__media">
                                    <h5>social media</h5>
                                    <div className="social__media--list">
                                        <a href="#" className="media"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-instagram"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-linkedin"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-x-twitter"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-behance"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


            <div className="rts-footer__copyright-two style-four m-0">
                <div className="container d-block">
                    <div className="row">
                        <div className="rts-footer__copyright-two__wrapper">
                            <p className="copyright">&copy; Copyright 2024. All Rights Reserved. PowerITBD</p>
                            <div className="payment__method">
                                <ul>
                                    <li><img src={bkash} alt="" style={{backgroundColor:"white",width:'120px',height:'40px'}}/></li>
                                    <li><img src={nagad} alt="" style={{backgroundColor:"white",width:'120px',height:'40px'}}/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useEffect, useState } from "react";
import styles from "./progress_wrap.module.css"; // Import the module CSS

import "../../assets/CustomerAsset/css/plugins.min.css";
import "../../assets/CustomerAsset/css/plugins/fontawesome.min.css";
// import "../../assets/CustomerAsset/css/customer.css";
import "../../assets/CustomerAsset/css/style.css";
import CustomerHeader from "@/Pages/CustomerSection/Menu/CustomerHeader";
import ClientFooter from "@/Pages/CustomerSection/FooterClient";

export default function CustomerLayout({ children,company }) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollPercent = (scrollTop / docHeight) * 100;
            setProgress(scrollPercent);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const strokeDasharray = 307.919;
    const strokeDashoffset = strokeDasharray - (strokeDasharray * progress) / 100;

    return (
        <div id="App">
            <div className="main-content light-theme p-0">
                <CustomerHeader company={company}/>

                {children}

                <ClientFooter company={company}/>

                <div
                    className={`${styles.progressWrap} ${progress > 0 ? styles.activeProgress : ''}`}
                    onClick={scrollToTop}
                >
                    <svg className={`progress-circle svg-content ${styles.progressCircle}`} width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            style={{
                                transition: 'stroke-dashoffset 10ms linear 0s',
                                strokeDasharray: `${strokeDasharray}, ${strokeDasharray}`,
                                strokeDashoffset: strokeDashoffset
                            }}
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

import React, {Component, useEffect, useState} from 'react';
import {Carousel} from "react-bootstrap";
import offer__background from "../../../../assets/CustomerAsset/images/banner/offer__background.jpg";
import off_one from "../../../../assets/CustomerAsset/images/50__off.png";
import offer__background_two from "../../../../assets/CustomerAsset/images/banner/offer__background-2.jpg";
import off_two from "../../../../assets/CustomerAsset/images/50__off-2.png";

export default function HomeSlider ({sliders}) {
    const [sliderData, setSliderData] = useState([]);

    const slides = [
        {
            slider_image: `${offer__background}`,
            slider_offer_image: `${off_one}`,
            offer_text: 'Save Big on Corporate Client Now',
            link: '#',
        },
        {
            slider_image: `${offer__background_two}`,
            slider_offer_image: `${off_two}`,
            offer_text: 'Unlock Exclusive Home User Offers Today',
            link: '#',
        },
    ];

    useEffect(() => {
        if (sliders.length > 0){
            setSliderData(sliders)
        }else{
            setSliderData(slides)
        }
    }, [sliders]);


    return (
        <>
            <div className="hero-banner-slide position-relative fix add__banner m-0">
                <div className="hero-banner-slider-wrapper">
                    <Carousel>
                        {sliderData.map((slide, i) => (
                            <Carousel.Item interval={4000} key={i}>
                                <div
                                    className="rts-hosting-banner hosting__offer__bg"
                                    style={{
                                        backgroundImage: `url(${slide.slider_image})`,
                                    }}
                                >
                                    <div className="container d-block">
                                        <div className="row justify-content-md-center justify-content-lg-start">
                                            <div className="col-lg-4">
                                                <div className="banner-area">
                                                    {slide.slider_offer_image?
                                                        <>
                                                            <div className="banner-area__content">
                                                                <img src={slide.slider_offer_image} alt="" />
                                                                <h5 className="banner-area__content__title">
                                                                    {slide.offer_text}
                                                                </h5>
                                                                <a href={slide.link} className="banner-area__content__link">
                                                                    View Now
                                                                </a>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="banner-area__content_blank">
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                {slide.slider_offer_image?
                                    "":
                                    <>
                                        <div className="carousel-caption w-auto text-center mb-4">
                                            <h5 style={{lineHeight:2}}> {slide.slider_text||""}</h5>
                                        </div>
                                    </>
                                }
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>

        </>
    );
}
// Media/SliderImage/1731932332qgr620.PI_S.png

{/*<section className="rts-hero-four rts-hero__four mern__hosting"*/}
{/*         style={{*/}
{/*             backgroundImage: `url(${bannerImage})`,*/}
{/*         }}*/}

{/*>*/}

{/*    <div className="container">*/}
{/*        <div className="row g-5 justify-content-between align-items-center">*/}
{/*            <div className="col-lg-8 col-xl-6 col-md-10">*/}
{/*                <div className="rts-hero-four__content" >*/}
{/*                    <p className="offer">Up to <span className="off">78%</span> off MERN Hosting</p>*/}
{/*                    <h1 className="banner__title" >*/}
{/*                        MERN Stack Hosting*/}
{/*                    </h1>*/}
{/*                    <p className="description">Find hosting tailored for MERN Stack applications,*/}
{/*                        offering full support for MongoDB, Express.js, React, and Node.js.*/}
{/*                    </p>*/}
{/*                    <div className="feature" >*/}
{/*                        <ul className="feature__list">*/}
{/*                            <li className="feature__item">Node.js Supported</li>*/}
{/*                            <li className="feature__item"> MongoDB Supported</li>*/}
{/*                            <li className="feature__item"> Litespeed Web Server</li>*/}
{/*                            <li className="feature__item"> 99.99% Uptime</li>*/}
{/*                            <li className="feature__item"> 24/7/365 Day Support</li>*/}
{/*                        </ul>*/}
{/*                    </div>*/}
{/*                    <div className="banner-buttons" >*/}
{/*                        <a href="#"*/}
{/*                           className="rts-btn btn__long secondary__bg secondary__color">get started <i*/}
{/*                            className="fa-solid fa-chevron-right"></i></a>*/}
{/*                        <a href="#" className="rts-btn btn__long border__white white__color">contact*/}
{/*                            us <i className="fa-solid fa-chevron-right"></i></a>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*            </div>*/}

{/*        </div>*/}
{/*    </div>*/}
{/*</section>*/}

import {toast, ToastContainer} from "react-toastify";
import React from "react";

const Notify = (type, message) => {
    return toast[type](message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })
}

export default Notify


// position="top-center"
// autoClose={3000}
// hideProgressBar
// newestOnTop
// closeOnClick
// rtl={false}
// pauseOnFocusLoss
// draggable
// pauseOnHover

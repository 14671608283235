import './bootstrap';
import '../css/app.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './store';
import ErrorBoundary from "@/Layouts/ErrorBoundary";

const appName = import.meta.env.VITE_APP_NAME || 'Virtual ISP Billing Advance';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <ToastContainer />
                    <App {...props} />
                </Provider>
            </ErrorBoundary>
        );
    },
    progress: false
    // progress: {
    //     color: '#1d72b8',  // Change the color to blue
    //     showSpinner: false,  // Show a spinner next to the progress bar
    //     delay: 200,         // Delay before showing the progress bar (in milliseconds)
    //     includeCSS: false,   // Include the default Inertia progress bar CSS
    // },
});

import React from 'react';
import logo from "../../../../assets/images/logo.png";
import CustomerNavigation from "@/Pages/CustomerSection/Menu/CustomerNavigation";
import emailImage from "../../../../assets/CustomerAsset/CommonImage/email.svg";
import forum from "../../../../assets/CustomerAsset/CommonImage/forum.svg";
import person from "../../../../assets/CustomerAsset/CommonImage/person.svg";
import {useInternalLink} from "@/Library/Helper";


export default function CustomerHeader({company}) {
    return (
        <>
            <header className="rts-header header__with__bg header__default">
                <div className="rts-ht rts-ht__bg">
                    <div className="container d-block">
                        <div className="row">
                            <div className="rts-ht__wrapper">
                                <div className="rts-ht__email d-flex mr-3">
                                    <a className="d-flex text-light" href={`mailto:${company?.email?company?.email:'samad1230@gmail.com'}`}>
                                        <img src={emailImage} alt=""  className="icon"/>{company?.email?company?.email:'samad1230@gmail.com'}
                                    </a>
                                </div>
                                <div className="rts-ht__promo mr-3">

                                </div>
                                <div className="rts-ht__links">
                                    <div className="live-chat-has-dropdown d-none d-sm-block">
                                        <a href="#" className="live__chat text-light"><img src={forum} alt="" className="icon"/>
                                        Live Chat
                                        </a>
                                    </div>
                                    <div className="login-btn-has-dropdown">
                                        <a
                                            href={route('admin.login')}
                                            target="_blank"
                                            className="login__link text-light"
                                        >
                                            <img src={person} alt="" className="icon"/>
                                            Admin Login
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="rts-menu">
                    <div className="container d-block">
                        <div className="row">
                            <div className="rts-header__wrapper">

                                <div className="rts-header__logo">
                                    <a href="#" className="site-logo">
                                        <img className="logo-white" src={company?.logo?useInternalLink(company?.logo):logo} width={50} alt="Power IT BD"/>
                                        <span className="company-name">{company?.name?company?.name:"Power IT BD"}</span>
                                    </a>
                                </div>

                                <CustomerNavigation/>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
